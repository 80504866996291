import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import clouds from 'static/airlines/norwegianholidays/images/graphics/clouds.svg';

function FooterComponentNorwegianHolidays() {
  return (
    <Footer>
      <Clouds src={clouds} />
      <FooterNote>
        <FormattedMessage
          id={'norwegian.countrySelection.footerNore'}
          description={
            '*2 checked bags per person, maximum 20 kg per bag. Select your seat when booking. (Seats in the forward part of the cabin, Premium seats and seats at emergency rows at additional cost). 2 % CashPoints to members of Norwegian Reward. (No points given on taxes and insurances.)'
          }
          defaultMessage={
            '*2 checked bags per person, maximum 20 kg per bag. Select your seat when booking. (Seats in the forward part of the cabin, Premium seats and seats at emergency rows at additional cost). 2 % CashPoints to members of Norwegian Reward. (No points given on taxes and insurances.)'
          }
        />
      </FooterNote>
    </Footer>
  );
}

export default FooterComponentNorwegianHolidays;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  line-height: initial;
`;

const Clouds = styled.img`
  width: 100%;
`;

const FooterNote = styled.div`
  position: absolute;
  top: 75%;
  z-index: 1000;
  padding: 0px;
  margin: auto;
  width: 100%;
  text-align: center;
  margin: 0 0 25px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.secondary.default};
`;
