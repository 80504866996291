import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';
import clouds from 'static/airlines/airmaltaholidays/images/graphics/clouds.svg';

function FooterComponentAirMaltaHolidays() {
  return (
    <Footer>
      <Clouds src={clouds} />
    </Footer>
  );
}

export default FooterComponentAirMaltaHolidays;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;

  ${mediaQueries.fromAndBelow.tablet`
    display: none;
  `}
`;

const Clouds = styled.img``;
