import React, { Component } from 'react';
import { connect } from 'react-redux';
import { COUNTRY_SELECTION as PAGE_COMPONENT_KEY_COUNTRY_SELECTION } from 'constants/pagesComponentsKeys';
import { setCurrentPageComponent } from 'state/currentPageComponent/actions';
import CountrySelectionComponent from 'routes/countrySelection/CountrySelectionComponent';
import fetchCountries from 'state/countryPicker/operations';
import { IN_PROGRESS as FETCHING_STATUS_IN_PROGRESS } from 'constants/status/fetchingStatus';
import SearchComponentLoading from 'routes/search/SearchComponentLoading';

class CountrySelection extends Component {
  static async getInitialProps({ req }) {
    // @TODO Extract to util or maybe set in _app.
    let hostname = process.browser ? window.location.hostname : undefined;
    if (!hostname) {
      const host = req.headers['host'];
      const protocol = host.indexOf('localhost') === -1 ? 'https' : 'http';
      hostname = `${protocol}://${host}`;
    }

    return {
      hostname,
    };
  }

  componentDidMount() {
    const { setCurrentPageComponent, fetchCountries, countryPickerItems } = this.props;
    if (!countryPickerItems) {
      fetchCountries();
    }
    setCurrentPageComponent(PAGE_COMPONENT_KEY_COUNTRY_SELECTION);
  }

  render() {
    const { hostname, logoUrl, airlineWhitelabel, availableCountries, countryPickerFetching, countryPickerItems } =
      this.props;

    if (countryPickerFetching === FETCHING_STATUS_IN_PROGRESS) {
      return <SearchComponentLoading headerBarFullHeight={0} />;
    }

    return (
      <CountrySelectionComponent
        hostname={hostname}
        logoUrl={logoUrl}
        airlineWhitelabel={airlineWhitelabel}
        countries={countryPickerItems}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    logoUrl: state.settings.value.style.logoUrl,
    airlineWhitelabel: state.settings.value.airlineWhitelabel,
    availableCountries: state.settings.value.availableCountries,
    countryPickerItems: state.countryPicker.items,
    countryPickerFetching: state.countryPicker.fetching,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentPageComponent(currentPageComponent) {
      dispatch(setCurrentPageComponent(currentPageComponent));
    },
    fetchCountries() {
      dispatch(fetchCountries());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelection);
