import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Margin } from 'styled-components-spacing';
import { FormattedMessage } from 'i18n';
import Countries from './components/countries/CountriesComponent';
import CountriesAirMaltaHolidays from './components/countries/CountriesComponent.AirMaltaHolidays';
import CountriesNorwegianHolidays from './components/countries/CountriesComponent.NorwegianHolidays';
import CountriesHillmanTravel from './components/countries/CountriesComponent.HillmanTravel';
import FooterComponentAirMaltaHolidays from './components/footer/FooterComponent.AirMaltaHolidays';
import FooterNorwegianHolidays from './components/footer/FooterComponent.NorwegianHolidays';
import FooterComponentHillmanTravel from './components/footer/FooterComponent.HillmanTravel';
import { mediaQueries } from 'styles/utils/mediaQueries';

function CountrySelectionComponent({ hostname, logoUrl, airlineWhitelabel, countries }) {
  return (
    <Root isAirMalta={airlineWhitelabel.isAirMalta} isCyprus={airlineWhitelabel.isCyprus}>
      {airlineWhitelabel && !airlineWhitelabel.isNorwegian ? (
        <Header>
          <Logo src={logoUrl} />
        </Header>
      ) : (
        <Fragment>
          <Header isNorwegian>
            <Logo src={logoUrl} isNorwegian />
          </Header>
          <Line />
        </Fragment>
      )}
      {airlineWhitelabel && !airlineWhitelabel.isNorwegian && <Margin top='2' />}
      <Headline>
        <HeadlinePrimary isNorwegian={airlineWhitelabel && airlineWhitelabel.isNorwegian}>
          <FormattedMessage
            id={`countrySelection.headlinePrimary.${airlineWhitelabel.hostname}`}
            description={''} // Don't add description and default message here!
            defaultMessage={''}
          />
        </HeadlinePrimary>
        <HeadlineSecondary isNorwegian={airlineWhitelabel && airlineWhitelabel.isNorwegian}>
          {airlineWhitelabel && !airlineWhitelabel.isNorwegian && (
            <FormattedMessage
              id={`countrySelection.headlineSecondary.${airlineWhitelabel.hostname}`}
              description={''} // Don't add description and default message here!
              defaultMessage={''}
            />
          )}
        </HeadlineSecondary>
      </Headline>
      {displayCountries(airlineWhitelabel, countries, hostname)}
      {displayFooter(airlineWhitelabel)}
    </Root>
  );
}

export default CountrySelectionComponent;

function displayCountries(airlineWhitelabel, countries, hostname) {
  if (airlineWhitelabel && airlineWhitelabel.isNorwegian) {
    return <CountriesNorwegianHolidays countries={countries} hostname={hostname} />;
  } else if (airlineWhitelabel && (airlineWhitelabel.isAirMalta || airlineWhitelabel.isCyprus)) {
    return (
      <CountriesAirMaltaHolidays countries={countries} hostname={hostname} isCyprus={airlineWhitelabel.isCyprus} />
    );
  } else if (airlineWhitelabel && airlineWhitelabel.isHillman) {
    return <CountriesHillmanTravel countries={countries} hostname={hostname} />;
  } else {
    return <Countries countries={countries} hostname={hostname} />;
  }
}

function displayFooter(airlineWhitelabel) {
  if (airlineWhitelabel && airlineWhitelabel.isNorwegian) {
    return <FooterNorwegianHolidays />;
  } else if (airlineWhitelabel && (airlineWhitelabel.isAirMalta || airlineWhitelabel.isCyprus)) {
    return <FooterComponentAirMaltaHolidays />;
  } else if (airlineWhitelabel.isHillman) {
    return <FooterComponentHillmanTravel />;
  }
}

const Root = styled.div`
  background-color: ${({ theme, isAirMalta, isCyprus }) =>
    isAirMalta ? '#aae1ff' : isCyprus ? '#BBDDE6' : theme.colors.primary.light};

  width: 100%;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  margin-bottom: -555px;

  ${mediaQueries.fromAndBelow.desktop`
    margin-bottom: -766px;
  `}

  @media (max-width: 538px) {
    margin-bottom: -783px;
  }

  @media (max-width: 522px) {
    margin-bottom: -807px;
  }
`;

const Header = styled.div`
  width: 100%;
  height: ${({ isNorwegian }) => (!isNorwegian ? 'unset' : '70px')};
  padding-top: ${({ isNorwegian }) => (!isNorwegian ? '68px' : 'unset')};
  background-color: ${({ theme, isNorwegian }) => (!isNorwegian ? 'unset' : theme.colors.adjacent.default)};
  text-align: center;
`;

const Logo = styled.img`
  margin: 0 auto;
  height: ${({ isNorwegian }) => (!isNorwegian ? '48px' : '63px')};
  width: ${({ isNorwegian }) => (!isNorwegian ? 'unset' : '150px')};
  padding-top: ${({ isNorwegian }) => (!isNorwegian ? 'unset' : '11px')};

  ${mediaQueries.fromAndAbove.tablet`
    height: ${({ isNorwegian }) => (!isNorwegian ? '96px' : '63px')};
  `}
`;

const Line = styled.div`
  width: 100%;
  height: 6px;
  margin-top: 3px;
  background-color: ${({ theme }) => theme.colors.secondary.default};
`;

const Headline = styled.div`
  margin: 0 12px ${mediaQueries.fromAndAbove.tablet`
    margin: unset;
  `};
`;

const HeadlinePrimary = styled.h1`
  margin: ${({ isNorwegian }) => (!isNorwegian ? '20px 0' : '20px 0 40px')};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.displaySubtle};
  font-size: ${({ isNorwegian }) => (!isNorwegian ? '2.4em' : '3.1em')};
  color: ${({ theme, isNorwegian }) => (!isNorwegian ? theme.colors.text.default : theme.colors.secondary.default)};
  line-height: 36px;

  ${mediaQueries.fromAndAbove.tablet`
    font-size: ${({ isNorwegian }) => (!isNorwegian ? '2.6em' : '3.3em')};
  `}
`;

const HeadlineSecondary = styled.h3`
  margin: 20px 0 20px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.displaySubtle};
  font-size: 1.7em;
  color: ${({ theme, isNorwegian }) => (!isNorwegian ? theme.colors.text.default : theme.colors.secondary.default)};
  line-height: 28px;

  ${mediaQueries.fromAndAbove.tablet`
    font-size: 1.9em;
  `}
`;
