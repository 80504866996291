import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';

function CountriesComponentHillmanTravel({ countries, hostname }) {
  return (
    <Countries>
      <CountriesWrap>
        {countries &&
          countries.map(({ countryCode, flagImage, countryTranslated, languageTranslated, url }) => (
            <Country href={url} key={countryCode} data-country-domain={countryCode}>
              <CountryIcon icon={flagImage} />
              <p>
                {countryTranslated} &#40;{languageTranslated}&#41;
              </p>
            </Country>
          ))}
      </CountriesWrap>
    </Countries>
  );
}

export default CountriesComponentHillmanTravel;

const Countries = styled.div`
  width: 100%;
  position: relative;
  z-index: 10;
  margin: 0 auto;
  padding-bottom: 50px;
  text-align: center;
  display:flex;
  justify-content: space-around;
`;

const CountriesWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 4rem;
`;

const Country = styled.a`
  padding: 12px;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  font-family: ${({ theme }) => theme.fonts.displaySubtle};
  color: ${({ theme }) => theme.colors.secondary.default};
  line-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${mediaQueries.fromAndBelow.tablet`
   justify-content: center;
  `}
  p {
    font-size: 22px;
    padding: 0.7rem;
    position: relative;
    color: ${({ theme }) => theme.colors.secondary.default};
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
  p::before {
    content: '';
    background-color: ${({ theme }) => theme.colors.secondary.default};
    position: absolute;
    left: 0;
    bottom: 5px;
    width: 100%;
    height: 4px;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }
  p:hover {
    color: #ffffff;
  }
  p:hover::before {
    bottom: 0;
    height: 100%;
  }
`;

const CountryIcon = styled.span`
  display: inline-block;
  margin-bottom: -3px;
  margin-right: 10px;
  height: 30px;
  width: 30px;
  background: ${({ icon }) => `url(${icon}) no-repeat center center`};
  background-color: ${({ theme }) => theme.colors.adjacent.default};
  border-radius: 50%;
`;
