import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HourglassLoader from 'components/spinners/HourglassLoader';
import flexbox from 'styles/utils/flexbox';

const propTypes = {
  headerBarFullHeight: PropTypes.number.isRequired,
};

const SearchComponentLoading = ({ headerBarFullHeight }) => (
  <SearchComponentLoadingStyled headerBarFullHeight={headerBarFullHeight}>
    <HourglassLoader />
  </SearchComponentLoadingStyled>
);

SearchComponentLoading.propTypes = propTypes;

export default SearchComponentLoading;

// Styled components
const SearchComponentLoadingStyled = styled.div`
  ${flexbox({})}
  /* margin-top: ${({ headerBarFullHeight }) => headerBarFullHeight}px; */
  height: ${({ headerBarFullHeight }) => `calc(100vh - ${headerBarFullHeight * 2}px)`};
`;
