import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import plane from 'static/airlines/airmaltaholidays/images/graphics/plane.svg';
import { mediaQueries } from 'styles/utils/mediaQueries';

function CountriesAirMaltaHolidays({ countries, hostname, isCyprus }) {
  return (
    <Countries>
      <CountriesWrap>
        {countries &&
          countries.map(({ countryCode, flagImage, countryTranslated, languageTranslated, url }) => (
            <Anchor href={url}>
              <Country key={countryCode} data-country-domain={countryCode}>
                <CountryIcon icon={flagImage} />
                {countryTranslated} &#40;{languageTranslated}&#41;
                <ChevronRight icon='chevron-down' />
              </Country>
            </Anchor>
          ))}
      </CountriesWrap>
      <PlaneImgWrapper>
        <PlaneImg src={plane} />
      </PlaneImgWrapper>
    </Countries>
  );
}

export default CountriesAirMaltaHolidays;

const borderRadius = '10px';

const Countries = styled.div`
  width: 290px;
  position: relative;
  z-index: 10;
  margin: 0 auto;
  padding-bottom: 50px;
  text-align: center;
`;

const CountriesWrap = styled.div`
  margin-top: 4rem;
  border-radius: ${borderRadius};
  box-shadow: 0 0 46px 0 rgba(0, 157, 225, 0.23);
  background-color: #ffffff;
  text-align: left;
`;

const Anchor = styled.a`
  position: relative;
  color: ${({ theme }) => theme.colors.primary.dark};
  font-family: ${({ theme }) => theme.fonts.displaySubtle};
  font-size: 1.8em;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
`;

const Country = styled.div`
  display: flex;
  white-space: nowrap;
  line-height: 28px;
  padding: 15px;
  border-bottom: 1px solid #a9e0ff !important;

  &:hover {
    background-color: #009de1;
    color: white;
  }

  ${Anchor}:first-of-type & {
    border-top-left-radius: ${borderRadius};
    border-top-right-radius: ${borderRadius};
  }

  ${Anchor}:last-of-type & {
    border-bottom-left-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
  }
`;

const ChevronRight = styled(FontAwesomeIcon)`
  margin-left: auto;
  transform: rotate(-90deg);
  height: 10px;
  margin-top: 10px;
  color: #1f419a;

  ${Country}:hover & {
    color: white;
  }
`;

const CountryIcon = styled.span`
  display: inline-block;
  margin-right: 10px;
  margin-top: 3px;
  height: 20px;
  width: 20px;
  background: ${({ icon }) => `url(${icon}) no-repeat`};
  border-radius: 50%;
`;

const PlaneImgWrapper = styled.div`
  width: 400px;
  height: 400px;
  z-index: -1;
  position: absolute;
  top: 35%;
  right: -100%;
  color: white;
`;

const PlaneImg = styled.img`
  width: 100%;

  ${mediaQueries.fromAndBelow.tablet`
    display: none;
  `}
`;
