import React from 'react';
import styled from 'styled-components';
import flexbox from 'styles/utils/flexbox';
import { mediaQueries } from 'styles/utils/mediaQueries';

function CountriesComponent({ countries, hostname }) {
  return (
    <Root>
      <Countries>
        {countries &&
          countries.map(({ domain, icon, countryName }) => (
            <Country key={domain} data-country-domain={domain}>
              <a href={`${hostname}/${domain}`}>
                <CountryIcon icon={icon} />
                {countryName}
              </a>
            </Country>
          ))}
      </Countries>
    </Root>
  );
}

export default CountriesComponent;

const Root = styled.div`
  ${flexbox({
    flexDirection: 'column',
    alignItems: 'center',
  })}

  margin-top: 75px;
`;

const Countries = styled.div`
  ${flexbox({
    flexWrap: 'wrap',
  })}

  ${mediaQueries.fromAndAbove.tablet`
    margin-left: 10%;
  `}
`;

const Country = styled.h3`
  flex: 0 1 100%;
  margin-bottom: 55px;
  margin-left: 25px;
  font-family: ${({ theme }) => theme.fonts.displaySubtle};
  font-size: 22px;
  font-weight: 500;

  a {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.text.default};
  }

  ${mediaQueries.fromAndAbove.tablet`
    flex: 0 1 33.33%;
    margin-left: unset;
  `}
`;

const CountryIcon = styled.span`
  display: inline-block;
  margin-right: 10px;
  height: 46px;
  width: 70px;
  background: ${({ icon }) => `url(${icon}) no-repeat center center`};
  background-size: contain;
  vertical-align: middle;
`;
