import styled, { keyframes } from 'styled-components';
import React from 'react';

const HourglassLoader = () => {
  return (
    <Root>
      <CupTop>
        <Sand></Sand>
      </CupTop>
      <CupBottom>
        <SandDelayed></SandDelayed>
      </CupBottom>
    </Root>
  );
};

const clock = keyframes`
 0%{
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25%,50%{
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  75%{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100%{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const sand = keyframes`
0%,100%{
   -webkit-border-radius:5%;
    border-radius: 5%;
    -webkit-transform:translate(35%,20%) rotate(45deg);
    transform: translate(35%,20%) rotate(45deg);
  }
  15%{
    -webkit-border-radius:40%;
    border-radius:40%;
    -webkit-transform:translate(0%,35%) rotate(-65deg) scale(1.5,1.5);
    transform: translate(0%,35%) rotate(-65deg) scale(1.5,1.5);
  }
  25%{
    -webkit-border-radius:20%;
    border-radius:20%;
    -webkit-transform:translate(-15%,35%) rotate(-90deg) scale(1.2,1.2);
    transform: translate(-15%,35%) rotate(-90deg) scale(1.2,1.2);
  }
  49.9%{
    -webkit-border-radius:5%;
    border-radius:5%;
    -webkit-transform:translate(0%,0%) rotate(-90deg) scale(1,1);
    transform: translate(0%,0%) rotate(-90deg) scale(1,1);
    -webkit-opacity: 1;
    opacity: 1;
  }
  50.01%,64.99%{
    -webkit-opacity: 0;
    opacity: 0;
  }
  65%{
    -webkit-border-radius:35%;
    border-radius:35%;
    -webkit-transform:translate(20%,50%) rotate(30deg);
    transform: translate(20%,50%) rotate(30deg);
    -webkit-opacity: 1;
    opacity: 1;    
  }
  75%{
    -webkit-border-radius:10%;
    border-radius:10%;
    -webkit-transform:translate(35%,40%) rotate(45deg);
    transform: translate(35%,40%) rotate(45deg);
  }    
`;

const Root = styled.div`
  position: relative;
  top: calc(50vh - 100px);
  left: calc(50vw - 50px);
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center; /*
  margin: auto;*/
  box-shadow: ${({ theme }) => `inset 0 0 0 0.25rem #aaa`};
  flex-direction: column;
  -webkit-animation: ${clock} 5s ease-in-out infinite;
  animation: ${clock} 5s ease-in-out infinite;
  transform: translateZ(0);
`;

const CupTop = styled.div`
  position: relative;
  background: #aaa;
  height: 30%;
  width: 30%;
  border-radius: 50% 50% 0 0/ 100% 100% 0 0;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  z-index: 0;
`;
const CupBottom = styled.div`
  position: relative;
  background: #aaa;
  height: 30%;
  width: 30%;
  border-radius: 50% 50% 0 0/ 100% 100% 0 0;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 0;
`;
const Sand = styled.div`
  background: ${({ theme }) => theme.colors.adjacent.default};
  height: 150%;
  width: 150%;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-animation: ${sand} 5s linear infinite -2.5s;
  animation: ${sand} 5s linear infinite -2.5s;
`;
const SandDelayed = styled.div`
  background: ${({ theme }) => theme.colors.adjacent.default};
  height: 150%;
  width: 150%;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-animation: ${sand} 5s linear infinite -2.5s;
  animation: ${sand} 5s linear infinite -2.5s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
`;

export default HourglassLoader;
