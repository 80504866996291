import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';

function CountriesComponentNorwegianHolidays({ countries, hostname }) {
  return (
    <Countries>
      <CountriesWrap>
        {countries &&
          countries.map(({ countryCode, flagImage, countryTranslated, languageTranslated, url }) => (
            <Country key={countryCode} data-country-domain={countryCode}>
              <a href={url}>
                <CountryIcon icon={flagImage} />
                {countryTranslated} &#40;{languageTranslated}&#41;
              </a>
            </Country>
          ))}
      </CountriesWrap>
    </Countries>
  );
}

export default CountriesComponentNorwegianHolidays;

const Countries = styled.div`
  width: 100%;
  position: relative;
  z-index: 10;
  margin: 0 auto;
  padding-bottom: 50px;
  text-align: center;

  ${mediaQueries.fromAndAbove.tablet`
    width: 280px;
    margin: 0 auto;
  `}
`;

const CountriesWrap = styled.div`
  margin-left: 10px;
  text-align: left;
`;

const Country = styled.h3`
  margin: 20px 0 20px;
  font-family: ${({ theme }) => theme.fonts.displaySubtle};
  font-size: 19px;
  color: ${({ theme }) => theme.colors.secondary.default};
  line-height: 28px;

  a {
    position: relative;
    border-bottom: 2px solid rgba(0, 61, 80, 0.3);
    color: ${({ theme }) => theme.colors.secondary.default};
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      border-bottom: 2px solid ${({ theme }) => theme.colors.secondary.default};
      text-decoration: none;
    }
  }
`;

const CountryIcon = styled.span`
  display: inline-block;
  margin-bottom: -3px;
  margin-right: 10px;
  height: 30px;
  width: 30px;
  background: ${({ icon }) => `url(${icon}) no-repeat center center`};
  background-color: ${({ theme }) => theme.colors.adjacent.default};
  border-radius: 50%;
`;
