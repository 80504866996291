import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';
import { ReactComponent as Clouds } from 'static/airlines/airmaltaholidays/images/graphics/clouds.svg';

function FooterComponentHillmanTravel() {
  return (
    <Footer>
      <Clouds />
    </Footer>
  );
}

export default FooterComponentHillmanTravel;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;

  ${mediaQueries.fromAndBelow.tablet`
    display: none;
  `}
  svg {
    .st0 {
      fill: ${({ theme }) => theme.colors.secondary.default};
    }
  }
`;
